<template>
    <div>
        <div style="background-image: url('../default/images/home/wave.png'); background-repeat: no-repeat; background-position: bottom; margin-bottom: -2px; background-size: contain;">
            <div class="container flex justify-around">
                <div class="pt-8">
                    <p class="text-primary text-3xl py-2 pt-8">Divirta-se com o amigo oculto</p>
                    <span class="text-primary-dark text-xl">Aqui você cria seu grupo, convida os seus amigos, realiza o sorteio!</span>
                </div>
                <div>
                    <img class="w-5/6 float-right" :src="baseUrl+'/default/images/home/friends.svg'">
                </div>
            </div>
        </div>

        <div class="text-center py-40 bg-primary">
            <h2>Quem somos</h2>
            <p></p>
            <p>Somos uma plataforma totalmente gratuita, que oference a experiência de realizar a famosa brincadeira de final de ano junto com seus amigos.</p>
        </div>

        <div class="text-center py-10 bg-main-gray-light text-sm">
            <h3>Dúvidas, sugestões, elogios ou jogar war?</h3>
            
            <p>Entre em contato conosco: <strong>contato@meuamigooculto.com.br</strong></p>
        </div>

        <div class="container flex justify-around py-28">
            <div class="text-center mx-10">
                <img :src="baseUrl+'/default/images/home/circle1.svg'">
                <p class="text-primary-dark font-semibold mt-5">Crie seu grupo</p>
            </div>
            <div class="text-center mx-10">
                <img :src="baseUrl+'/default/images/home/circle2.svg'">
                <p class="text-primary-dark font-semibold mt-5">Convide seus amigos</p>
            </div>
            <div class="text-center mx-10">
                <img :src="baseUrl+'/default/images/home/circle3.svg'">
                <p class="text-primary-dark font-semibold mt-5">Escolha seus presentes</p>
            </div>
        </div>

        <div class="mb-10">
            <div class="container px-6 py-10 mx-auto">
                <h1 class="text-2xl font-semibold text-primary-dark text-center">Perguntas frequentes</h1>

                <hr class="my-6 border-gray-20">

                <div v-for="item in questions" :key="item.question">
                    <div>
                        <button class="flex items-center focus:outline-none" @click="showAnswer(item.id)">
                            <img v-if="item.show" class="flex-shrink-0 w-6 h-6 text-primary" :src="baseUrl+'/default/images/home/minus.svg'">
                            <img v-if="!item.show" class="flex-shrink-0 w-6 h-6 text-primary" :src="baseUrl+'/default/images/home/plus.svg'">
                              <h1 class="mx-4 text-xl text-gray-700">{{item.question}}</h1>
                        </button>
                        <div class="flex mt-8 md:mx-10" v-if="item.show">
                            <span class="border-l border-l-primary"></span>

                            <p class="max-w-3xl px-4 text-gray-500" v-html="item.answer"></p>
                        </div>
                    </div>

                    <hr class="my-8 border-gray-200">
                        
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                baseUrl: process.env.MIX_APP_URL ?? 'https://www.meuamigooculto.com.br',
                questions: [
                    {
                        id: 1,
                        question: "Como me cadastro?",
                        answer:`<a class="btn-link" href="/register">Clique aqui</a> ou na opção <strong>'Cadastrar'</strong> no menu.<br> Para o cadastro, basta informar seu nome completo, um email válido, sua data de nascimento e senha.<br>Após o cadastro, você receberá um e-mail de confirmação.`,
                        show: false
                    },
                    {
                        id: 2,
                        question: "Como faço o login?",
                        answer:`<a class="btn-link" href="/login">Clique aqui</a> ou na opção <strong>'Login'</strong> no menu.<br> Na página de login, você deve informar seu email cadastrado e senha.<br> Após efetuar o login, você será redirecionado para sua página inicial.`,
                        show: false
                    },
                    {
                        id: 3,
                        question: "Como crio meu grupo de amigos?",
                        answer:`Após fazer login, clique no menu <strong>grupos</strong> e depois em <strong>criar grupo</strong>.`,
                        show: false
                    },
                    {
                        id: 4,
                        question: "Como procuro por um grupo e solicito para participar dele?",
                        answer:`<p>Basta ir na opção <strong>"Grupos"</strong> do menu para acessar a página de pesquisa. <br>Digite o nome do grupo e clique em <strong>procurar</strong></p><p>Após encontrar o grupo desejado, você pode clicar em <strong>pedir para participar</strong>.<br>Sua participação no grupo depende da aprovação do administrador desse grupo. Ele será notificado por e-mail sobre sua solicitação.</p>`,
                        show: false
                    },
                    {
                        id: 5,
                        question: "Como envio convite para meus amigos participar do meu grupo?",
                        answer:`<p> Na página do seu grupo, clique no ícone <span class="p-2 bg-white"><i class="fa-solid fa-ellipsis-vertical"></i></span> e clique a opação <strong>"convidar amigos"</strong>. <br>
                                Nessa nova tela, você poderá escolher dentre três formas para convidar seus amigos:<br>
                                <ul class="pl-4 text-sm">
                                    <li class="py-2"><strong>- Copiar link:</strong> copie o link de convite ou gere um novo e envie para seus amigos. Seu amigo deve ter uma conta para conseguir acessar o convite do grupo pelo link.</li>
                                    <li class="py-2"><strong>- Convide amigo já cadastrado:</strong> Caso seu amigo já tenha uma conta criada, você pode pesquisar pelo nome ou email de cadastro dele e clicar em <strong>convidar</strong>. Seu amigo receberá um email informando sobre o convite.</li>
                                    <li class="py-2"><strong>- Convide por email:</strong> Digite o email de seu(s) amigo(s) e depois clique em <strong>enviar convites</strong>. Seu(s) amigo(s) deve ter uma conta para conseguir acessar o convite do grupo por email.</li>
                                </ul>
                                </p>`,
                        show: false
                    },
                    {
                        id: 6,
                        question: "Como entrar em um grupo?",
                        answer:`<p>Você pode entrar em um grupo <strong>solicitando para entrar</strong> ou recebendo um <strong>convite por link</strong>.</p>`,
                        show: false
                    },
                    {
                        id: 7,
                        question: "Como realizo um sorteio?",
                        answer:`<p>
                                <strong>Se você for o criator(a) do grupo</strong> e o grupo tiver no mínimo três participantes, você pode realizar o sorteio da seguinte forma:
                                <ul class="pl-4 text-sm">
                                    <li class="py-2">Entre na página do seu grupo.</li>
                                    <li class="py-2">No canso superior onde aparece a imagem/banner do grupo, você pode clicar no ícone de engrenagem <i class="fas fa-cog"></i></li>
                                    <li class="py-2">O menu do grupo irá abrir e aparecerá a opção <i class="fa-solid fa-wand-magic-sparkles"></i> realizar sorteio</li>
                                    <li class="py-2">Um modal de confirmação aparecerá e após confirmar que quer realizar o sorteio, todos os participantes do grupo receperão um email avisando que o sorteio já foi realizado.</li>
                                </ul>
                                </p>`,
                        show: false
                    },
                    {
                        id: 8,
                        question: "Como crio uma lista de presentes?",
                        answer:`<p>Basta ir na opção <strong>"Presentes"</strong> do menu para acessar a <u>página de presentes</u>.
                                <br>
                                Seleciona o botão <strong>"adicionar presente"</strong> na parte superior da página.
                                <br>
                                Seleciona o(s) grupo(s) onde deseja adicionar o presente e depois você pode <u>pesquisar</u> o presente que quer ganhar ou adicionar as informações do presente <u>manualmente</u>.
                                <br><br>
                                <strong><u>Importante:</u></strong> A pesquisa por produto é feita utilizando um serviço externo do <a href="https://developers.mercadolivre.com.br/pt_br/api-docs-pt-br" target="_blank"><strong>Mercado Livre</strong>.</p>`,
                        show: false
                    },
                    {
                        id: 9,
                        question: "Como entro em contato com alguém do Meu Amigo Oculto?",
                        answer:`<p>Você pode enviar um email para: <strong>contato@meuamigooculto.com.br</strong>.<br>Retornaremos o contato o mais breve possível.</p>`,
                        show: false
                    },
                    {
                        id: 10,
                        question: "O serviço do Meu Amigo Oculto é pago?",
                        answer:`<p><strong>Não!</strong> O site <strong>Meu Amigo Oculto</strong> é um projeto totalmente de graça.</p>`,
                        show: false
                    },
                    {
                        id: 11,
                        question: "Não achei o que queria saber nesse Perguntas Frequentes. O que faço?",
                        answer:`<p>Você pode enviar um email para: <strong>contato@meuamigooculto.com.br</strong>.<br>Retornaremos o contato o mais breve possível.</p>`,
                        show: false
                    }
                ]
            }
        },
        methods: {
            showAnswer(questionId) {
                this.questions.forEach((element) => {
                    element.show = element.id === questionId ? !element.show : false;
                });
                
            }
        }
    }
</script>
